import React, { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoCheckmarkCircle, IoWarningOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { LOGIN_CODE } from "../constants/code";
import { useUser } from "../context/userContext";
import { getUserInfo, login, verify2fa } from "../services/auth.service";
import FormControls from "./FormControl";
import Spinner from "./Spinner";

const LoginComp = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const navigate = useNavigate();
  const [errorCode, setErrorCode] = useState("");
  const [login2fa, setLogin2fa] = useState(false);
  const [code2fa, setCode2fa] = useState("");
  const telegramUserid = window.Telegram.WebApp.initDataUnsafe?.user?.id;

  const { isLogin, setIsLogin, handleUser } = useUser();
  const handleForm = async (event) => {
    event.preventDefault();
    if (login2fa) {
      handleVerify2fa();
      return;
    }
    handleLogin();
  };

  const handleVerify2fa = async () => {
    if (!code2fa) {
      setErrorCode("Please enter 2fa code.");
      setTimeout(() => {
        setErrorCode("");
      }, 1000);
      return;
    }

    try {
      setLoading(true);
      const { data } = await verify2fa(code2fa, username);
      if (data.success === LOGIN_CODE.SUCCESS) {
        await handleOnLoginSuccess();
        return;
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleLogin = async () => {
    if (!username || !password) {
      setErrorCode("Please enter username and password.");
      setTimeout(() => {
        setErrorCode("");
      }, 1000);
      return;
    }
    try {
      setLoading(true);
      const { data } = await login(username, password);

      if (data.success === LOGIN_CODE.VERIFY_2FA) {
        setLogin2fa(true);
        setLoading(false);
        return;
      }

      if (data.success === LOGIN_CODE.SUCCESS) {
        localStorage.setItem("tokenUser", data.token);
        await handleOnLoginSuccess();
        setLoading(false);
        return;
      }
    } catch (error) { }
    setLoading(false);
  };

  const handleOnLoginSuccess = async () => {
    setErrorCode("");
    setSuccessMessage("Login successful! Redirecting to dashboard...");
    const user = await getUserInfo();
    if (user.data.success === 1) {
      handleUser(user.data);
      setIsLogin(true);
    }

    navigate("/", { replace: true });
  };

  return (
    <>
      {successMessage ? (
        <Spinner />
      ) : (
        <div className="flex flex-col items-center form-wrapper">
          <img src="/logo.jpg" className="w-full h-auto object-contain md:w-[100px]" />
          <div className="card border-t-2 border-[#0D8BF1] w-full">
            <div className="w-full pb-5 text-center">
              <h1 className="pt-2 font-bold tracking-tight text-white text-2xl/9">
                Welcome to the <br />
                AutoBot Ai MiniApp
              </h1>
              <p className="text-[13px] text-white">
                Automate, Innovate, Dominate: Trade Smarter with AI
              </p>
            </div>
          </div>

          <div className="flex flex-col items-center w-full space-y-3 sm:space-y-4">
            {login2fa ? (
              <FormControls
                label="Verify 2fa"
                type="text"
                id="2fa"
                placeholder="2fa"
                value={code2fa}
                setValue={setCode2fa}
              />
            ) : (
              <>
                {" "}
                <FormControls
                  label="Username"
                  type="text"
                  id="username"
                  placeholder="username"
                  value={username}
                  setValue={setUsername}
                />
                <FormControls
                  label="Password"
                  type="password"
                  id="password"
                  placeholder="**********"
                  value={password}
                  setValue={setPassword}
                />
              </>
            )}
            <div className="flex flex-col items-center w-full">
              <div className="flex flex-col items-center space-y-6 sm:space-y-4 w-full sm:w-[47%]">
                <button
                  className="flex justify-center w-full px-3 py-3 text-sm font-semibold leading-6 text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={handleForm}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      Logging in
                      <AiOutlineLoading3Quarters
                        size={18}
                        className="animate-spin ml-[12px]"
                      />
                    </>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorCode && (
        <div className="z-[60] ease-in duration-300 w-full fixed left-0 right-0 px-4 top-6">
          <div className="w-full text-[#ff4d4d] flex items-center space-x-2 px-4 bg-[#121620ef] h-[50px] rounded-[8px]">
            <IoWarningOutline size={16} />
            <span className="text-[15px]">{errorCode}</span>
          </div>
        </div>
      )}

      {successMessage && (
        <div className="z-[60] ease-in duration-300 w-full fixed left-0 right-0 px-4 top-6">
          <div className="w-full text-[#4dff4d] flex items-center space-x-2 px-4 bg-[#121620ef] h-[50px] rounded-[8px]">
            <IoCheckmarkCircle size={16} />
            <span className="text-[15px]">{successMessage}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginComp;

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React from "react";
import { HiX } from "react-icons/hi";
import { Button } from "./Button";

const Modal = ({
  visible = false,
  onOk,
  onCancel,
  className,
  okText = "OK",
  contentClassName,
  cancelText = "Cancel",
  children,
  centered,
  title,
  hideOkBtn,
  hideCancelBtn,
  hidenClose,
  loading = false,
  isEnableOk = false,
  isEnableSecond = false,
  secondText = "",
  onSecond,
  disabledAll = false,
}) => {
  const onClose = () => {
    onCancel && onCancel();
  };

  return (
    <Dialog open={visible} onClose={onClose} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-[#101828] px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full md:w-[80vw] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="pb-2 font-semibold text-white border-b">
              {" "}
              {title}
              <div className="sm:block absolute top-0 right-0 pt-5 pr-5 z-[999]">
                <button
                  type="button"
                  className=" rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                  onClick={onClose}
                >
                  <span className="sr-only">Close</span>
                  <HiX className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="mt-4 sm:flex sm:items-start">{children}</div>
            <div className="flex gap-3 mt-5 sm:mt-4 ">
              <Button
                onClick={onOk}
                disabled={!isEnableOk || disabledAll}
                type="primary"
                className={"w-full"}
              >
                {okText}
              </Button>
              {secondText !== "" && (
                <Button
                  onClick={onSecond}
                  type="secondary"
                  disabled={!isEnableSecond || disabledAll}
                  className={"w-full"}
                >
                  {secondText}
                </Button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Modal;

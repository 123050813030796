import React, { createContext, useContext, useEffect, useState } from "react";
import Spinner from "../Components/Spinner";
import { getUserInfo } from "../services/auth.service";
import { useAuthContext } from "./AuthContext";

const UserContext = createContext();
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [balance, setBalance] = useState(0);
  const [email, setEmail] = useState("");
  const [nickName, setNickName] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [isLogin2fa, setIsLogin2fa] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [characterMenu, setCharacterMenu] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState({
    id: "selectex",
    icon: "/exchange.svg",
    name: "Select exchange",
  });
  const [userAi, setUserAi] = useState();
  const [loading, setLoading] = useState(true);
  const { doLogin, doLogout } = useAuthContext();

  const fetchUser = async () => {
    try {
      if (user?.id) setLoading(false);

      const { data } = await getUserInfo();
      if (data.success === 1) {
        doLogin();
        setUser(data.user);
        handleUser(data);
      } else if (data.success === 4) {
        localStorage.removeItem("tokenUser");
        doLogout();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUser = (user) => {
    console.log(user);

    setIsLogin(true);
    setEmail(user.user.email);
    setNickName(user.user.nick_name);
    setBalance(user.user.balance);
    setUserAi(user.userAi);
  };

  useEffect(() => {
    let token = localStorage.hasOwnProperty("tokenUser");

    if (token) {
      //get user info
      setIsLogin(true);
    }
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        balance,
        email,
        nickName,
        loading,
        setLoading,
        initialized,
        setInitialized,
        telegramUser,
        characterMenu,
        setCharacterMenu,
        selectedExchange,
        setSelectedExchange,
        isLogin,
        isLogin2fa,
        setIsLogin,
        setNickName,
        userAi,
        handleUser,
      }}
    >
      {loading ? <Spinner /> : children}
    </UserContext.Provider>
  );
};

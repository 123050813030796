import React, { useEffect, useState } from "react";
import {
  AiFillAlert,
  AiFillClockCircle,
  AiFillDollarCircle,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useUser } from "../../context/userContext";
import { getHistoryBet } from "../../services/auto.service";
import { formatMoney, formatNumber } from "../../utils";
import { formatDate } from "../../utils/date";
import Animate from "../Animate";
import CardRound from "../CardRound";
import Pagination from "../ui/Pagination";

const SettingsPage = () => {
  const { nickName, balance, email, userAi } = useUser();

  const [loadingHistory, setLoadingHistory] = useState(true);
  const [userPackageHistories, setUserPackageHistories] = useState([]);

  const fetchUserPackageHistories = async () => {
    try {
      const { data } = await getHistoryBet();
      setUserPackageHistories(data.result);
    } catch (error) {}
    setLoadingHistory(false);
  };

  useEffect(() => {
    fetchUserPackageHistories();
  }, []);

  return (
    <Animate>
      <div className="flex flex-col justify-center w-full px-3 pt-4 space-y-3">
        <CardRound>
          <div className="flex justify-between py-5 gap-x-6">
            <div className="flex min-w-0 gap-x-4">
              <img
                alt=""
                src={
                  "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                }
                className="flex-none bg-white rounded-full size-12 "
              />
              <div className="flex-auto min-w-0">
                <p className="text-base font-semibold text-white">{nickName}</p>
                <p className="mt-1 text-sm truncate text-white/80">{email}</p>
              </div>
            </div>
            <div className="flex flex-col items-end justify-center shrink-0">
              <p className="text-sm text-white">
                {" "}
                <span>{formatMoney(balance)}</span>
              </p>
              <p className="mt-1 text-sm text-white/80">
                Last login at{" "}
                {formatDate(userAi.logined_at, {
                  dateFormat: "HH:mm DD/MM/YY",
                })}
              </p>
            </div>
          </div>
        </CardRound>

        <div className="mt-2">
          <CardRound loading={loadingHistory}>
            <div className="card-title">
              <h3 className="flex justify-between font-bold text-white uppercase ">
                Bet Histories
              </h3>
            </div>
            <div className="flex w-full gap-2 mt-2 ">
              {userPackageHistories.length == 0 ? (
                <div>No data avaiable</div>
              ) : (
                <div className="flex flex-col w-full gap-2 bet-items">
                  {userPackageHistories.map((historyItem, idx) => (
                    <div
                      className="flex flex-col bg-[#4c535f] rounded-sm shadow-lg p-2 w-full"
                      key={idx}
                    >
                      <div className="flex flex-row justify-between">
                        <div className="flex flex-row items-center text-xs">
                          <AiFillClockCircle
                            size={14}
                            className="mr-1"
                          ></AiFillClockCircle>
                          {historyItem.created_at}
                        </div>
                        {historyItem.amount_win > 0 ? (
                          <span className="float-right bg-teal-500 badge">
                            Win
                          </span>
                        ) : (
                          <span className="float-right badge bg-rose-500">
                            Lose
                          </span>
                        )}
                      </div>
                      <div className="flex flex-row items-center text-base">
                        <AiFillAlert size={14} className="mr-1"></AiFillAlert>
                        {historyItem.buy_sell} |{" "}
                        {formatNumber(historyItem.open, 3)} -{" "}
                        {formatNumber(historyItem.close, 3)}
                      </div>
                      <div className="flex flex-row items-center text-base">
                        <AiFillDollarCircle
                          size={14}
                          className="mr-1"
                        ></AiFillDollarCircle>
                        Bet: {formatNumber(historyItem.amount_bet, 2)}$
                      </div>
                      <div className="flex flex-row items-center text-base">
                        <AiFillDollarCircle
                          size={14}
                          className="mr-1"
                        ></AiFillDollarCircle>
                        Profit:
                        {historyItem.amount_win > 0 ? (
                          <span className="text-teal-500">
                            +{formatNumber(historyItem.amount_win, 2)}$
                          </span>
                        ) : (
                          <span className="text-teal-500">
                            -{formatNumber(historyItem.amount_lose, 2)}$
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="mt-2">
                    <Pagination pageSize={10} total={10} current={1} />
                  </div>
                </div>
              )}
            </div>
          </CardRound>
        </div>
      </div>
    </Animate>
  );
};

export default SettingsPage;

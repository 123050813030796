import { Dialog, DialogPanel } from "@headlessui/react";
import { HiMiniXMark } from "react-icons/hi2";

export default function Drawer({ children, open, setOpen }) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed bottom-24 right-0 flex max-w-full h-[70vh]">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-y-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-[#101828] shadow-xl rounded-t-2xl">
                <div className="px-4 py-6 sm:px-6">
                  <div className="flex items-start justify-between">
                    <h2
                      id="slide-over-heading"
                      className="text-base font-semibold text-white"
                    >
                      Settings
                    </h2>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-[#101828] text-white hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <HiMiniXMark aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="px-5 pb-4">{children}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

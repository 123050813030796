import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import "../fire.scss";

const tele = window.Telegram.WebApp;
const MainFullScreen = () => {
  const location = useLocation();

  useEffect(() => {
    tele.ready();
    tele.expand();

    window.Telegram.WebApp.setHeaderColor("#1b1b1b"); // Set header color to red

    // Haptic feedback
    if (tele.HapticFeedback) {
      tele.HapticFeedback.impactOccurred("medium");
    }
  }, []);

  const overflow = 100;
  const scrollableEl = useRef(null);

  useEffect(() => {
    window.scrollTo(0, overflow);

    let ts;

    const onTouchStart = (e) => {
      ts = e.touches[0].clientY;
    };

    const onTouchMove = (e) => {
      const el = scrollableEl.current;
      if (el) {
        const scroll = el.scrollTop;
        const te = e.changedTouches[0].clientY;
        if (scroll <= 0 && ts < te) {
          e.preventDefault();
        }
      } else {
        e.preventDefault();
      }
    };
    const onTouchMoveWithException = (e) => {
      const target = e.target.closest("#refer");
      if (!target) {
        onTouchMove(e);
      }
    };

    document.documentElement.addEventListener("touchstart", onTouchStart, {
      passive: false,
    });
    document.documentElement.addEventListener(
      "touchmove",
      onTouchMoveWithException,
      { passive: false }
    );

    // Cleanup event listeners on component unmount
    return () => {
      document.documentElement.removeEventListener("touchstart", onTouchStart);
      document.documentElement.removeEventListener(
        "touchmove",
        onTouchMoveWithException
      );
    };
  }, [location.pathname, overflow]);

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full space-y-3">
            <AnimatePresence mode="wait">
              <Outlet />
            </AnimatePresence>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MainFullScreen;

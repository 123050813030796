import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "../fire.scss";

const tele = window.Telegram.WebApp;
const Main = () => {
  const location = useLocation();

  useEffect(() => {
    tele.ready();
    tele.expand();

    window.Telegram.WebApp.setHeaderColor("#1b1b1b"); // Set header color to red

    // Haptic feedback
    if (tele.HapticFeedback) {
      tele.HapticFeedback.impactOccurred("medium");
    }
  }, []);

  const overflow = 100;
  const scrollableEl = useRef(null);


  return (
    <>
      <div className="flex justify-center w-full">
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            <AnimatePresence mode="wait" key={1}>
              <div>
                <Header />
                <div className="mt-[57px] h-[calc(100vh-57px-72px)] overflow-y-auto" id="main-content">
                  <Outlet />
                </div>
                <div
                  id="footermain"
                  className={` visible z-30 flex flex-col fixed bottom-0 left-0 right-0 justify-center items-center`}
                >
                  <Footer />
                </div>
              </div>
            </AnimatePresence>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { getUsePackages } from "../../services/packages.service";
import { formatNumber } from "../../utils";
import Animate from "../Animate";

import CardRound from "../CardRound";

const MyPackagePage = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State để kiểm soát loading

  const [userPackages, setUserPackages] = useState([]);
  const [isLoadUserPackage, setIsLoadUserPackage] = useState(false);

  const doInitPage = async () => {
    doLoadUserPackage();
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const doLoadUserPackage = async () => {
    setIsLoadUserPackage(true); // Kích hoạt loading
    await fetchUserPackage();
    setTimeout(() => {
      // Giả lập quá trình tải dữ liệu
      setIsLoadUserPackage(false); // Tắt loading sau 2 giây
    }, 1000);
  };

  const fetchUserPackage = async () => {
    try {
      const { data } = await getUsePackages(1, 10);
      setUserPackages(data.result);
    } catch (error) {}
  };

  const gotoUserPackage = async (id) => {
    navigate(`/my-package/${id}`);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    doInitPage();
  }, []);

  if (loading) {
    // Hiển thị màn hình loading nếu đang tải
    return (
      <div className="flex items-center justify-center h-screen bg-[#202938]">
        <TailSpin color="#ffffff" height={50} width={50} />
      </div>
    );
  }

  return (
    <Animate>
      <div className="page-body">
        <div className="container mx-auto px-3 py-4">
          <CardRound loading={isLoadUserPackage}>
            <div className="flex flex-row justify-between card-title">
              <h3 className="font-bold text-white uppercase ">Your packages</h3>
            </div>
            <div className="mt-2 card-body">
              <div className="flex flex-col w-full gap-2 package-items">
                {userPackages.length ? (
                  userPackages.map((packageItem) => (
                    <div className="kanban-item" key={packageItem.id}>
                      <a
                        className="kanban-box"
                        
                        onClick={() => gotoUserPackage(packageItem.id)}
                      >
                        <div>
                          <span className="text-sm">
                            {packageItem.created_at}
                          </span>
                          {packageItem.is_run == 1 ? (
                            <span className="float-right bg-teal-600 badge">
                              Active
                            </span>
                          ) : (
                            <span className="float-right bg-orange-600 badge">
                              Unactive
                            </span>
                          )}
                        </div>
                        <h6 className="text-lg font-bold text-white">
                          {packageItem.name}
                        </h6>
                        <div className="media-body text-md">
                          <p>{packageItem.memo}</p>
                        </div>
                        <div className="flex w-full gap-2 mt-2">
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg py-1">
                            <span className="text-sm font-bold text-center text-white">
                              Profit
                            </span>
                            <span className="text-sm text-center text-gray-400">
                              {packageItem.profit >= 0 ? (
                                <span className="text-green-600">
                                  +{formatNumber(packageItem.profit, 2)}$
                                </span>
                              ) : (
                                <span className="text-rose-600">
                                  {formatNumber(packageItem.profit, 2)}$
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                            <span className="text-sm font-bold text-center text-white">
                              Profit today
                            </span>
                            <span className="text-sm text-center text-gray-400">
                              {packageItem.today_profit >= 0 ? (
                                <span className="text-green-600">
                                  +{formatNumber(packageItem.today_profit, 2)}$
                                </span>
                              ) : (
                                <span className="text-rose-600">
                                  {formatNumber(packageItem.today_profit, 2)}$
                                </span>
                              )}
                            </span>
                          </div>
                          <div className="flex flex-col flex-1  bg-[#4c535f] rounded-md shadow-lg  py-1">
                            <span className="text-sm font-bold text-center text-white">
                              Win/Lose
                            </span>
                            <span className="text-sm text-center text-gray-400">
                              {packageItem.win}/{packageItem.lose}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))
                ) : (
                  <span> No data available</span>
                )}
              </div>
            </div>
          </CardRound>
        </div>
      </div>
    </Animate>
  );
};

export default MyPackagePage;

import React from "react";
import "../App.css";
import Animate from "../Components/Animate";
import HomeComp from "../Components/HomeComp";
import Login from "../Components/LoginComp";
import Spinner from "../Components/Spinner";
import { useUser } from "../context/userContext";
import "../fire.scss";

const tele = window.Telegram.WebApp;
const Home = () => {
  const { loading, isLogin } = useUser();

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Animate>
          <div className="w-full pt-3">
            <div className="flex flex-col justify-center w-full px-3 space-y-3">
              <HomeComp />
            </div>
          </div>
        </Animate>
      )}
    </>
  );
};

export default Home;
